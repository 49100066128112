import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Section, Container } from "../global"

const ITEMS = [
  { name: "START", price: 10, documents: 15, employees: 0 },
  { name: "STANDARD", price: 20, documents: 30, employees: 1 },
  { name: "PLUS", price: 30, documents: 100, employees: 3 },
]

const Prices = ({ signUp, initialSelection, onSelected }) => {
  const [state, setState] = useState({
    selected: initialSelection,
  })

  const handleClick = (name) => {
    setState({ ...state, selected: name })
    if (onSelected) onSelected(name)
  }

  const handleButtonClick = (name) => {
    setState({ ...state, selected: name })
    navigate("/sign-up", {
      state: { subscription: name },
    })
  }

  return (
    <Section id="prices">
      <StyledContainer>
        {signUp ? null : <Subtitle>Preturi</Subtitle>}
        {signUp ? null : (
          <SectionTitle>Pachete pentru orice afacere</SectionTitle>
        )}
        <Subscriptions>
          {ITEMS.map((item) => (
            <Subscription
              key={item.name}
              selected={signUp && item.name === state.selected}
              onClick={() => handleClick(item.name)}
            >
              <Title>{item.name}</Title>
              <Price>{item.price} EUR/Luna</Price>
              <Text>{item.documents} documente</Text>
              <Text>
                {item.employees} angajat{item.employees !== 1 ? "i" : ""}
              </Text>
              {signUp ? null : (
                <Button onClick={() => handleButtonClick(item.name)}>
                  Alege
                </Button>
              )}
            </Subscription>
          ))}
        </Subscriptions>
      </StyledContainer>
    </Section>
  )
}

export default Prices

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const Subscriptions = styled.div`
  display: flex;
  justify-content: center;
  & > div:not(:first-child) {
    margin-left: 40px;
  }
`

const Subscription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 1em;
  border-radius: 5px;

  ${(props) =>
    props.selected
      ? `outline: 2px solid red;
      outline-offset: 5px;`
      : null}
`

const Title = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 10px;
`

const Price = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: red;
`

const Text = styled.p`
  text-align: center;
`

const Button = styled.button`
  font-family: ${(props) => props.theme.font.normal};
  ${(props) => props.theme.font_size.xsmall};
  color: white;
  background: #098b8c;
  border-radius: 4px;
  padding: 10px 16px;
  text-transform: uppercase;
  font-size: 12px;
`
