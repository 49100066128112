import React, { useState } from "react"
import styled from "styled-components"

import { Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"
import Prices from "../components/sections/prices"

const SignUpPage = ({ location }) => {
    const [state, setState] = useState({
        firstName: { value: "", isValid: false },
        lastName: { value: "", isValid: false },
        email: { value: location?.state?.email ?? "", isValid: false },
        phone: { value: "", isValid: false },
        fiscalCode: { value: "", isValid: false },
        companyName: { value: "", isValid: false },
        subscription: { value: location?.state?.subscription ?? "", isValid: false },
        acceptTerms: { value: false, isValid: false },
        formValid: false,
    })

    const handleChange = ({ target }) => {
        const { name, value } = target
        const newState = { ...state, [name]: { value: value, isValid: value ? true : false } };
        newState.formValid = validateForm(newState);
        setState(newState);
    }

    const handleCheckboxChange = ({ target }) => {
        const { name } = target;
        const newState = {
            ...state,
            [name]: { value: !state[name].value, isValid: !state[name].value }
        };
        newState.formValid = validateForm(newState);
        setState(newState);
    };

    const handleSelected = (name) => {
        const newState = {
            ...state, subscription: { value: name, isValid: name ? true : false }
        };
        newState.formValid = validateForm(newState);
        setState(newState);
    }

    const validateForm = (newState) => {
        const isValid = newState.firstName.isValid &&
            newState.lastName.isValid &&
            newState.email.isValid &&
            newState.phone.isValid &&
            newState.fiscalCode.isValid &&
            newState.companyName.isValid &&
            newState.acceptTerms.isValid;
        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        registerUser()
    }

    const registerUser = async () => {
        try {
            const query = JSON.stringify({
                query: `query RegisterUser($input:RegisterUserInput!){
      registerUser(input: $input) {
        result
      }
    }`,
                variables: {
                    input: {
                        firstName: state.firstName.value,
                        lastName: state.lastName.value,
                        email: state.email.value,
                        phone: state.phone.value,
                        fiscalCode: state.fiscalCode.value,
                        companyName: state.companyName.value,
                        acceptTerms: state.acceptTerms.value
                    },
                },
            })

            const response = await fetch(`${process.env.GATSBY_API_URL}`, {
                method: "post",
                body: query,
                headers: {
                    "Content-Type": "application/json",
                    "Content-Length": query.length,
                },
            })

            await response.json()
        } catch (error) {
            console.warn(error)
        }
    }

    return (
        <Layout>
            <SEO title="Inregistrare" />
            <Navigation />
            <StyledContainer>
                <Wrapper>
                    <Title>Inregistrare</Title>
                    <Form onSubmit={handleSubmit}>
                        <FieldsWrapper>
                            <TextFieldsWrapper>
                                <Input
                                    name="firstName"
                                    placeholder="Prenume"
                                    value={state.firstName.value}
                                    onChange={handleChange}
                                />
                                <Input
                                    name="lastName"
                                    placeholder="Nume"
                                    value={state.lastName.value}
                                    onChange={handleChange}
                                />
                                <Input
                                    name="email"
                                    placeholder="Email"
                                    value={state.email.value}
                                    onChange={handleChange}
                                />
                                <Input
                                    name="phone"
                                    placeholder="Telefon"
                                    value={state.phone.value}
                                    onChange={handleChange}
                                />
                                <Input
                                    name="fiscalCode"
                                    placeholder="Cod fiscal"
                                    value={state.fiscalCode.value}
                                    onChange={handleChange}
                                />
                                <Input
                                    name="companyName"
                                    placeholder="Companie"
                                    value={state.companyName.value}
                                    onChange={handleChange}
                                />
                                <Checkbox
                                    name="acceptTerms"
                                    label="Accept termenii si conditiile"
                                    isSelected={state.acceptTerms.value}
                                    onCheckboxChange={handleCheckboxChange}
                                />
                            </TextFieldsWrapper>

                            <Prices
                                signUp={true}
                                initialSelection={state.subscription.value}
                                onSelected={handleSelected}
                            />
                        </FieldsWrapper>
                        <Button disabled={!state.formValid} onclick={async () => { await registerUser() }}>Inregistrare</Button>
                    </Form>
                </Wrapper>
            </StyledContainer>
            <Footer />
        </Layout>
    )
}

export default SignUpPage

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TextFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20em;
  margin-right: 40px;
`

const Title = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  input,
  button {
    margin-top: 1em;
  }
`

const Input = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 100%;
  }
`

const Button = styled.button`
  align-self: center;
  padding: 0 2em;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.disabled ? '#CCCCCC' : props.theme.color.secondary};
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
`

const CheckboxBase = ({ name, label, isSelected, onCheckboxChange, className }) => (
    <div className={className}>
        <input
            type="checkbox"
            name={name}
            checked={isSelected}
            onChange={onCheckboxChange}
            className="form-check-input"
        />
        <label htmlFor={name}>{label}</label>
    </div>
);

const Checkbox = styled(CheckboxBase)`
label{
    margin-left: 0.5em;
}
`